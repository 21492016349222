























































import Vue from 'vue'
import Component from 'vue-class-component'

import Content from '@/components/Content.vue'
import Toolbar from '@/components/Layout/Toolbar.vue'
import { formatDate } from '@/util/formatDate'

import { InspirationApi, InspirationMessageDto } from '@/api'

@Component({
  components: {
    Content,
    Toolbar
  },
  methods: {
    formatDate
  },
  computed: {
    currentUser () {
      return this.$store.state.currentUser
    }
  }
})
export default class Inspiration extends Vue {
  public inspirationItems: InspirationMessageDto[] = [];
  public selectedItem: InspirationMessageDto | null = null;

  constructor () {
    super()
    this.fetch()
  }

  public selectItem (item: InspirationMessageDto) {
    this.selectedItem = item
  }

  public back () {
    if (this.selectedItem) {
      this.selectedItem = null
    } else {
      this.$router.go(-1)
    }
  }

  async fetch () {
    const api = new InspirationApi()
    try {
      const { data } = await api.inspirationGetInspirationMessages()
      this.inspirationItems = data
    } catch (e) {
      // ...
    }
  }
}
