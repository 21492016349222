export function formatDate (date: string) {
  if (date === '') {
    return date
  }

  const objDate = new Date(date)
  if (!objDate) {
    return date
  }
  if (objDate.getFullYear() === 1) {
    return ''
  }
  const months = [
    'januari',
    'februari',
    'maart',
    'april',
    'mei',
    'juni',
    'juli',
    'augustus',
    'september',
    'oktober',
    'november',
    'december'
  ]
  return `${objDate.getDate()} ${months[objDate.getMonth()]} ${objDate.getFullYear()}`
}
